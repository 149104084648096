import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import {
  InstantSearch,
  SearchBox,
  Hits,
  Stats,
  Pagination,
} from 'react-instantsearch-dom';
import RecipeLink from '../components/recipe-link';
import algoliasearch from 'algoliasearch/lite';

const StyledAlgolia = styled.div`
  .ais-SearchBox {
    width: 100%;
  }

  .ais-SearchBox-form {
    position: relative;
    border-bottom: 1px solid #aaa;
    display: flex;
    justify-content: space-between;
  }

  .ais-SearchBox-input {
    border: none;
    padding: 0.2em;
    font-size: 1.4em;
    flex-grow: 1;
    outline: none;
  }

  .ais-Pagination {
    ul {
      display: flex;
      list-style: none;
      justify-content: center;
      padding: 0;
    }
    li {
      span {
        font-weight: 300;
      }
      a {
        font-weight: 400;
      }
      a,
      span {
        font-size: 1.2em;
        padding: 0.5em;
      }
    }
  }
`;

function SearchResults() {
  return (
    <StaticQuery
      query={searchResultsQuery}
      render={(data) => {
        const algolia = data.site.siteMetadata.algolia;
        const searchClient =
          algolia &&
          algolia.appId &&
          algoliasearch(algolia.appId, algolia.searchOnlyApiKey);
        return (
          <StyledAlgolia>
            {algolia && algolia.appId && (
              <InstantSearch
                indexName={algolia.indexName}
                searchClient={searchClient}
              >
                <SearchBox translations={{ placeholder: 'Search' }} autoFocus />
                <Stats />
                <Hits hitComponent={RecipeLink} />
                <Pagination />
              </InstantSearch>
            )}
          </StyledAlgolia>
        );
      }}
    />
  );
}

export default SearchResults;

export const searchResultsQuery = graphql`
  query SearchResultsQuery {
    site {
      siteMetadata {
        algolia {
          appId
          searchOnlyApiKey
          indexName
        }
      }
    }
  }
`;
