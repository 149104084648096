import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";

const RecipeLink = props => {
    const { hit } = props;
  
    return ( hit && hit.fields) ? (
      <Link to={'/recipe' +  hit.fields.slug} >
        {hit.frontmatter.title}
      </Link>
    ): '';
  };
  
  RecipeLink.propTypes = {
    hit: PropTypes.object.isRequired
  };
  
  export default RecipeLink; 