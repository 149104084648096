import React from "react";
import PropTypes from "prop-types";
import { graphql } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"
import SearchResults from "../components/search-results";

class SearchPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title
    return (
      < Layout location={this.props.location} title={'Search ' + siteTitle}>
        <SEO title='Search Food I Made Recipes' description='Search all of the recipes on foodimade.com' />
        <SearchResults/>
      </Layout >
    )
  }
}

SearchPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default SearchPage;

//eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query AlgoliaQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;